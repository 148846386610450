/* eslint-disable react/no-multi-comp */
import React, { ForwardedRef, PropsWithChildren, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import ProfilePageMobile from '../../../assets/images/home/profile-page-mobile.jpg';
import CertPageMobile from '../../../assets/images/home/cert-page-mobile.jpg';
import zuhlke from '../../../experiences/zuhlke';
import ncs from '../../../experiences/ncs';
import macquarie from '../../../experiences/macquarie';
import { Experience } from '../../layouts/Resume';
import dhl from '../../../experiences/dhl';
import rcg from '../../../experiences/rcg';
import nova from '../../../experiences/nova';
import ncsph from '../../../experiences/ncsph';
import ust from '../../../experiences/ust';
import kforce from '../../../experiences/kforce';
import goldenway from '../../../experiences/goldenway';
import Contact from '../../../assets/images/home/contactme-mobile.jpg';
import phoenix from '../../../experiences/phoenix';
import JobSkillsMobile from './components/JobSkillsMobile';
import { combinedListOfSkillsArr } from './inputs';
import { computeTotalYears } from '../../util/util';
import './css/page.css';
import JobSummaryPageMobile from '../../pages/JobSummaryPage/JobSummaryPageMobile';
import tpicap from '../../../experiences/tpicap';

// eslint-disable-next-line react/display-name
const Page = React.forwardRef(
  (props: PropsWithChildren<any>, ref: ForwardedRef<any>) => {
    return (
      <div ref={ref} style={{ height: '100%', width: '100%' }}>
        <div>{props.children}</div>
        {/* {props.number && props.number > 1 && (
          <footer
            style={{
              textAlign: 'center',
              position: 'absolute',
              bottom: '10px',
            }}
          >
            Page {props.number} of {props.total}
          </footer>
        )} */}
      </div>
    );
  }
);

const MobileBook = ({ actions }: { actions: any }) => {
  const ref = useRef<HTMLElement>(null);
  const experiences: Experience[] = [
    tpicap,
    zuhlke,
    ncs,
    macquarie,
    dhl,
    rcg,
    nova,
    ncsph,
    ust,
    kforce,
    goldenway,
    phoenix,
  ];
  let pages: any[] = [];
  const skills = combinedListOfSkillsArr;
  for (let x = 0; x < experiences.length; x++) {
    const experience = experiences[x];
    pages.push(<JobSummaryPageMobile {...experience} />);
  }

  const turnToPage = (pageNumber: number) => {
    if (null !== ref.current) {
      // @ts-ignore
      ref.current.pageFlip().turnToPage(pageNumber);
    }
  };

  actions.turnToPage = turnToPage;

  return (
    <>
      {/*
      // @ts-ignore */}
      <HTMLFlipBook
        height={400}
        maxHeight={1200}
        maxWidth={400}
        minHeight={400}
        minWidth={300}
        ref={ref}
        showCover
        size={'stretch'}
        style={{ margin: '0px 0px', padding: '0px 0px' }}
        usePortrait
        width={300}
      >
        <Page>
          <div style={{ height: '100vh', display: 'flex' }}>
            <img className="cover-image" src={ProfilePageMobile} />
          </div>
        </Page>
        <Page number="1" total={pages.length + 3}>
          <div style={{ height: '100vh', display: 'flex' }}>
            <span
              id={'total'}
              style={{
                position: 'absolute',
                left: '2px',
                top: '20px',
                fontSize: '2.3rem',
                zIndex: 1,
                fontFamily: 'cartoon',
              }}
            >
              {computeTotalYears(experiences)}
            </span>
            <img height="100%" src={CertPageMobile} width="100%" />
          </div>
        </Page>
        <Page total={pages.length + (1 + skills.length)}>
          <div style={{ height: '100vh', display: 'flex' }}>
            <JobSkillsMobile skills={skills} />
          </div>
        </Page>
        {pages.map((page, index) => {
          return (
            <Page
              key={`page-${index}`}
              number={index + 4}
              total={pages.length + (1 + skills.length)}
            >
              <div style={{ height: '100vh', display: 'flex' }}>{page}</div>
            </Page>
          );
        })}
        <Page>
          <img className="cover-image" src={Contact} />
        </Page>
      </HTMLFlipBook>
    </>
  );
};

export default MobileBook;
