import RCGLogo from '../assets/images/rcg/rcg-logo.jpeg';
import PhilippineFlag from '../assets/images/flags/philippine-flag.jpg';
import RCGOffice from '../assets/images/rcg/rcg-office.jpeg';
import MacquarieLogo from '../assets/images/logo/company/macquarie-logo.jpeg';
import DisneyLogo from '../assets/images/rcg/disney-logo.png';
import DojoLogo from '../assets/images/logo/tech/dojo.png';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import WebMethodsLogo from '../assets/images/logo/tech/webmethods.png';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import JunoArchitecture from '../assets/images/rcg/juno-architecture.png';
import LiloArchitecture from '../assets/images/rcg/lilo-architecture.png';

export default {
  backgroundColor: '#454ab5',
  subBackgroundColor: '#3651b3',
  logoHeight: '60px',
  color: '#ffffff',
  company: 'RCG Offshore Services',
  position: 'Senior Software Engineer',
  fromDate: 'Mar-01-2014',
  fromDateResume: '03/2014',
  toDate: 'Feb-28-2015',
  toDateResume: '02/2015',
  picture: RCGLogo,
  description:
    'RCG Offshore Services is an IT Service provider in the Philippines with numerous clients around the world.' +
    ' Here in RCG, we are deployed to clients in helping them build softwares that would improve there business overtime.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: PhilippineFlag,
  officeImg: RCGOffice,
  pageNum: 26,
  projects: [
    {
      client: 'Macquarie Offshore Services',
      color: '#000000',
      name: 'Juno - Trade Confirmation Platform',
      role: 'Senior Java Developer',
      description:
        'Juno is a trade confirmation platform in Macquarie used by backoffice users to automatically match trades bought by counterparties and brokers. ' +
        'Juno integrates with other services via web methods (SOA) to receive the trades and  automatically generate the confirmations pdf and sent to both parties.',
      logo: MacquarieLogo,
      img: JunoArchitecture,
      architecture: JunoArchitecture,
      modules: [
        {
          name: 'Macquarie - Juno Trade Confos',
          description: 'Core developer',
          involvements: [
            {
              name: 'Devised and executed substantial enhancements to the Juno platform that boosted functionality',
              indent: false,
            },
            {
              name: 'Successfully spearheaded the upgrade of WebMethods during the annual technical refresh, ensuring seamless operations',
              indent: false,
            },
            {
              name: 'Implemented a critical upgrade of Java from version 6 to 8, significantly enhancing system performance by 30%',
              indent: false,
            },
            {
              name: 'Collaborated closely with Technical Architects to guarantee flawless integration of affected systems during the technical refresh',
              indent: false,
            },
          ],
        },
      ],
    },
    {
      client: 'Walt Disney',
      color: '#3e6bc4',
      name: 'Lilo - Disney Membership Application',
      role: 'Senior Java Developer',
      description:
        'Walt Disney is a well known entertainment business in the U.S and frequent clients has an option to be a walt disney member. ' +
        'LILO is the name of the application that maintains the walt disney member information , points they earn and the gifts they are entitled to.',
      logo: DisneyLogo,
      img: LiloArchitecture,
      architecture: LiloArchitecture,
      modules: [
        {
          name: 'Lilo - Disney Membership Application',
          description: 'Core developer',
          involvements: [
            {
              name: 'Collaborate with Business Analyst to develop new features in LILO',
              indent: false,
            },
            {
              name: 'Develop new user interface (UI) features to display confirmation messages when claiming points',
              indent: false,
            },
            {
              name: 'Fix critical bugs reported in Production environment',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: DojoLogo,
      title: 'Dojo.io',
      info: 'UI for Juno',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: WebMethodsLogo,
      title: 'WebMethods',
      info: 'ESB',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
  ],
};
