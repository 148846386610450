export const sideprojects = [
  {
    name: 'GS Engage (1st Place) Hackathon',
    description: `Participated in a hackathon organized by Goldmansachs on the problem statement of "Customizable Report Rendering" and led the team to formulate a unique solution that won the 1st place.
    Attended all the mentoring sessions and presented the solution during the hackathon to a panel of judges.
    Developed the proof of concept (POC) code for the solution and presented it during the demo and final presentation.
    `,
    link: 'https://slides.com/jeddayo/gsengage',
    github: 'https://github.com/jesus-dayo/json-excel',
  },
  {
    name: 'Occideas',
    description:
      'Occideas is a cancer research application used by data scientists to find probable cause of different types of cancer. ' +
      ' This application is now being used by several countries on their research. I am one of the main contributors to this project. ' +
      ' The application is built using SpringBoot, AngularJs, MySQL, Spring Security, Kantu and Hibernate.',
    link: 'https://www.occideas.org/',
    github: 'https://github.com/DataScientists/OccIDEAS',
  },
  {
    name: 'Personal Website',
    description:
      'My website is my living portfolio which I update frequently. My website shows a magazine as my CV and it' +
      ' also provides a download resume feature for the viewer. The download resume generates an ATS Word doc of my CV and ' +
      ' it uses the information in the Magazine to generate it. ' +
      ' My website is created using ReactJs, TypeScript , React FlipBook , React Docx, ChartJs and AWS Static hosting via S3 and Cloudfront. ',
    link: 'https://www.jeddayo.com',
    github: 'https://github.com/jesus-dayo',
  },
  {
    name: 'TindahanSuppliesPh',
    description:
      'My self-funded e-commerce website in the Philippines, however due to the pandemic , I had to closed it down temporarily due to restrictions in delivery. ' +
      ' I created the website using ReactJs, Proptypes, Recoil, GraphQL, AWS Amplify, AWS DynamoDB, AWS ElasticSearch ,' +
      ' Paypal Integration, AWS SNS, AWS Lambda and SEO using Google Analytics + Ads. ' +
      ' I have future plans to change the business model of this website to be a simple storage of different suppliers in the Philippines.',
    link: 'https://www.tindahansupplies.com',
    github: 'https://github.com/jesus-dayo',
  },
];
