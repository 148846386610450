import USTLogo from '../assets/images/ust/ust-logo.png';
import PhilippineFlag from '../assets/images/flags/philippine-flag.jpg';
import USTPHOffice from '../assets/images/ust/ust-office.jpeg';
import HEBLogo from '../assets/images/ust/heb-logo.jpeg';
import USTArchitecture from '../assets/images/ust/ust-architecture.png';
import MySQL from '../assets/images/logo/tech/mysql_logo.jpeg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import JavaSwing from '../assets/images/logo/tech/java-swing.png';

export default {
  color: '#ffffff',
  backgroundColor: '#960d0d',
  subBackgroundColor: '#8a1f1f',
  company: 'UST Global Inc',
  position: 'Mid Java Engineer',
  fromDate: 'Mar-01-2012',
  fromDateResume: '03/2012',
  toDate: 'Nov-29-2012',
  toDateResume: '11/2012',
  picture: USTLogo,
  description:
    'UST Global Inc in Philippines is located at Taguig City , it is a well known IT service provider in the U.S.\n' +
    'We develop and provide software services to our U.S clients.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: PhilippineFlag,
  officeImg: USTPHOffice,
  pageNum: 38,
  projects: [
    {
      client: 'HEB (Here everything matters)',
      name: 'HEB Desktop Pharmacy',
      role: 'Mid Java Engineer',
      description:
        'This application manages the stocks and maintains an inventory of medicines. ' +
        'On a daily basis, the batch job would sync the stock inventory with a centralized webservice call.',
      color: '#092847',
      logo: HEBLogo,
      architecture: USTArchitecture,
      img: USTArchitecture,
      logoWidth: '150px',
      modules: [
        {
          name: 'HEB Desktop Pharmacy',
          description: 'Core developer',
          involvements: [
            {
              name: 'Developed and implemented HEB Whiteboard, a web application that revolutionized meetings by enabling interaction, storage, and automatic generation of meeting minutes.',
              indent: false,
            },
            {
              name: 'Automated testing to enhance code quality and efficiency in the existing HEB desktop pharmacy system.',
              indent: false,
            },
            {
              name: 'Designed and unit tested new Java components for the HEB desktop pharmacy system.',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: JavaSwing,
      title: 'java Swing',
      info: 'Built UI in MPA',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: MySQL,
      title: 'MySQL Database',
      info: 'database is in MySQL',
    },
  ],
};
