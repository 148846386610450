export const skills2 = [
  { name: 'AngularJS', years: 5, color: '#e31111' },
  { name: 'Angular', years: 1, color: '#11b9e3' },
  { name: 'Hibernate', years: 7, color: '#323a3c' },
  { name: 'Spring Batch', years: 4, color: '#53083c' },
  { name: 'DynamoDB', years: 2, color: '#efbade' },
  { name: 'Recoil', years: 1, color: '#070d77' },
  { name: 'Redux', years: 3, color: '#774107' },
  { name: 'AWS Amplify', years: 3, color: '#726d3e' },
  { name: 'Kubernetes', years: 3, color: '#3e7257' },
];
