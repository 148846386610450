import React from 'react';
import Grid from '@material-ui/core/Grid';
import SkillChart from './SkillChart';


const JobSkills = ({skills}:{skills: any[]}) => {
  return (
    <Grid container style={{backgroundColor: '#eeebe6', height: '800px'}}>
      <Grid item style={{height: '90%', margin: '0px 0px'}} xs={12}>
        <SkillChart skills={skills}/>
      </Grid>
    </Grid>
  )
}

export default JobSkills;
