import NCSLogo from '../assets/images/logo/company/ncs-logo.jpg';
import PhilippineFlag from '../assets/images/flags/philippine-flag.jpg';
import NCSPHOffice from '../assets/images/ncsph/ncsph-office.jpeg';
import MPALogo from '../assets/images/ncsph/mpa-logo.png';
import MPAArchitecture from '../assets/images/ncsph/mpa-architecture.png';
import OracleWeblogic from '../assets/images/logo/tech/oracle-webloguc-logo.jpg';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import JSF from '../assets/images/logo/tech/jsf.jpeg';

export default {
  color: '#ffffff',
  backgroundColor: '#376D8A',
  subBackgroundColor: '#5fa8d0',
  company: 'NCS Philippines',
  position: 'Senior System Analyst',
  fromDate: 'Dec-01-2012',
  fromDateResume: '12/2012',
  toDate: 'Oct-30-2013',
  toDateResume: '10/2013',
  picture: NCSLogo,
  description:
    'NCS IT Services in Philippines is located at Taguig City and like its headquarters in Singapore, it is a well known IT service provider in the Philippines.\n' +
    'I was sent to NCS Singapore to work on a web application for one of the Singapore government entity.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: PhilippineFlag,
  officeImg: NCSPHOffice,
  pageNum: 34,
  projects: [
    {
      client: 'Maritime Port Authority of Singapore',
      name: 'Marinet Web Application',
      role: 'Senior System Analyst',
      color: '#092847',
      description:
        'Maritime Port Authority (MPA) had a legacy system (mainframe) to capture and charge different see vessels based on duration of stay and size of vessel. ' +
        ' MPA would like to revamp and modernize the system by introducing a new web application that would provide see vessels to declare and apply for ' +
        ' port permit prior to arrival in Singapore ports. The new web application is a multi-module project built by NCS.',
      logo: MPALogo,
      architecture: MPAArchitecture,
      img: MPAArchitecture,
      logoWidth: '150px',
      modules: [
        {
          name: 'MPA Revamp Project (Part 1)',
          description: 'Senior System Analyst / Developer',
          involvements: [
            {
              name: 'Provided exceptional support to clients by actively attending workshops in the MPA office to gather, analyze, and fully understand complex business requirements',
              indent: false,
            },
            {
              name: 'Delivered high-quality results by effectively documenting all requirements in functional specifications and expertly translating these into technical specification documents',
              indent: false,
            },
            {
              name: 'Showcased exceptional technical skills and knowledge by collaborating closely with our esteemed Technical Architect to design smooth and effective external interface integration for the new application',
              indent: false,
            },
            {
              name: 'Demonstrated an unwavering commitment to excellence by working hand-in-hand with the Technical Architect to design a seamless development infrastructure setup for the application, ensuring optimal functionality at every stage of the process',
              indent: false,
            },
          ],
        },
        {
          name: 'MPA Revamp Project (Part 2)',
          description: 'Senior System Analyst / Developer',
          involvements: [
            {
              name: 'Successfully developed and provided an innovative proof of concept for the new application using Spring MVC + JSF – delivering optimal results at every step of the way',
              indent: false,
            },
            {
              name: 'Expertly collaborated with and assigned tasks to a team of highly-skilled developers based in China, ensuring seamless operation and efficient task completion throughout all stages of development',
              indent: false,
            },
            {
              name: 'Demonstrated exceptional leadership skills by conducting thorough and insightful interviews for potential new applicants joining NCS Philippines, making sure only the best candidates were selected',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: JSF,
      title: 'JSF',
      info: 'Built UI in Marinet using JSF',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: OracleWeblogic,
      title: 'Oracle Web Logic',
      info: 'App Server',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
  ],
};
