import ZuhlkeSvg from '../assets/images/logo/company/zuhlke.svg';
import SingaporeFlag from '../assets/images/flags/singapore-flag.png';
import ZuhlkeSingaporeOffice from '../assets/images/zuhlke/zuhlke-office.jpg';
import JBLogo from '../assets/images/clients/juliusbaer_logo.png';
import SIAMobile from '../assets/images/projects/sia/sia_mobile.png';
import SIAArchitecture from '../assets/images/projects/sia/sia_architecture.png';
import ReactLogo from '../assets/images/logo/tech/react-logo.png';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import AwsLogo from '../assets/images/logo/tech/aws-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import KubernetesLogo from '../assets/images/logo/tech/kubernetes-logo.png';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import FlowableLogo from '../assets/images/logo/tech/flowable-logo.jpg';
import ClippArchitecture from '../assets/images/projects/clipp/clipp-architecture.png';
import ClippRecognition from '../assets/images/projects/clipp/clippProject.png';
import CSLogo from '../assets/images/clients/cs-logo.jpeg';
import CSArchitecture from '../assets/images/projects/credit-suisse/cs-corporate-action.png';

export default {
  backgroundColor: '#86498e',
  subBackgroundColor: '#c778d0',
  color: '#ffffff',
  company: 'Zuhlke Engineering',
  position: 'Expert Software Engineer',
  fromDate: 'Jun-25-2019',
  fromDateResume: '06/2019',
  toDate: 'Jun-28-2022',
  toDateResume: '06/2022',
  picture: ZuhlkeSvg,
  description:
    'Zuhlke Engineering in Singapore is a well known IT service provider.\n' +
    'I worked here from June 25, 2019 till June 28, 2022 as an Expert Software Engineer.',
  page: '/resume/job-detail',
  country: 'Singapore',
  flagImg: SingaporeFlag,
  officeImg: ZuhlkeSingaporeOffice,
  pageNum: 5,
  projects: [
    {
      client: 'Credit Suisse',
      color: '#092847',
      name: 'Corporate Action Service',
      role: 'Senior Fullstack Developer',
      description:
        'Corporate Actions is an internal web application for Relationship Managers and ARMs. The ' +
        'application mainly maintains the corporate action events such as dividends options announced by business entities.',
      logo: CSLogo,
      img: CSArchitecture,
      architecture: CSArchitecture,
      modules: [
        {
          name: 'Corporate Action Halogen UI',
          description: 'Core developer',
          involvements: [
            {
              name: 'Collaborated seamlessly with stakeholders to brainstorm, ideate and craft stunning designs for an innovative application.',
              indent: false,
            },
            {
              name: 'Developed cutting-edge React components and dynamic pages for the Standing Instructions feature of the application.',
              indent: false,
            },
            {
              name: 'Elevated the UI design while optimizing UX flow to significantly enhance the overall quality of the application.',
              indent: false,
            },
            {
              name: 'Pioneered the use of advanced React Testing Library effectively replacing traditional Enzyme testing methods for superior testing capabilities.',
              indent: false,
            },
            {
              name: 'Integrated Cypress for robust end-to-end testing ensuring high-quality standards for the application.',
              indent: false,
            },
          ],
        },
        {
          name: 'Corporate Action Halogen UI',
          description: 'Core developer',
          involvements: [
            {
              name: 'Delivered comprehensive training sessions on Cypress to multiple teams at Credit Suisse, empowering them to leverage the power of end-to-end testing like never before.',
              indent: false,
            },
            {
              name: 'Demonstrated unparalleled expertise in ReactJS and Cypress by conducting Knowledge Transfer (KT) sessions within the team to drive growth and innovation.',
              indent: false,
            },
            {
              name: 'Pioneered the implementation of GitLab pipelines for halogen UI, streamlining the development process and enhancing collaboration across teams.',
              indent: false,
            },
            {
              name: 'Reviewed pull requests from other developers with meticulous attention to detail, providing feedback to ensure adherence to best practices and code quality standards.',
              indent: false,
            },
          ],
        },
        {
          name: 'Corporate Action Service API',
          description: 'Core developer',
          involvements: [
            {
              name: 'Exhibited a keen eye for detail by reviewing and meticulously analyzing pull requests from fellow developers to ensure adherence to best practices and code quality standards.',
              indent: false,
            },
            {
              name: 'Successfully expanded the functionality of an existing API by integrating new dividend option events to be returned to the UI, resulting in a more intuitive user experience and increased customer satisfaction.',
              indent: false,
            },
            {
              name: 'Elevated the quality of unit testing in the backend by implementing Behavior-Driven Development (BDD) methodology, thereby improving the reliability and robustness of the application.',
              indent: false,
            },
          ],
        },
      ],
    },
    {
      client: 'Bank Julius Baer',
      color: '#5177c9',
      name: 'Pricing Simulation - Client Pricing Portal',
      role: 'Senior Fullstack Developer',
      description:
        'Pricing Simulation is a Web Application that manages pricing fee discounts for client portfolios. ' +
        ' This application is also know as "CLIPP" CLient Pricing Portal.' +
        ' CLIPP has a user friendly interface to enter the pricing requests and provide revenue simulations to the RM.' +
        ' For all approved pricing requests, CLIPP stores and sends this requests to T24 via BCOM.',
      logo: JBLogo,
      img: ClippArchitecture,
      architecture: ClippArchitecture,
      recognition: ClippRecognition,
      modules: [
        {
          name: 'CLIPP Development (Part 1)',
          description: 'Core developer for CLIPP',
          involvements: [
            {
              name: 'Demonstrated excellent communication skills by meticulously translating complex requirements documents into detailed technical specifications, ensuring clear understanding among all stakeholders.',
              indent: false,
            },
            {
              name: 'Successfully developed highly scalable and efficient Java components using Spring Boot and Hibernate, meeting strict quality standards and delivering exceptional performance.',
              indent: false,
            },
            {
              name: 'Designed and implemented a sophisticated approval workflow in CLIPP using the embedded Flowable.io platform, resulting in streamlined processes and enhanced productivity.',
              indent: false,
            },
            {
              name: 'Created dynamic and visually appealing GUI components using ReactJS, Context Hooks, and Combined Reducers, delivering an immersive user experience, and improving overall application usability.',
              indent: false,
            },
          ],
        },
        {
          name: 'CLIPP Development (Part 2)',
          description: 'Core developer for CLIPP',
          involvements: [
            {
              name: 'Continuously improved the development pipeline by expertly integrating tools such as Jenkins, Bitbucket, Sonar, Docker, Kubernetes, and Skaffold, facilitating faster deployments and seamless collaboration across teams.',
              indent: false,
            },
            {
              name: 'Expertly reviewed and provided constructive feedback on pull requests from other developers, ensuring quality standards were met and contributing to a culture of collaboration and continuous improvement.',
              indent: false,
            },
            {
              name: 'Served as a trusted coach and mentor to other developers, providing guidance and support to enable personal and professional growth, and contributing to the development of high-performing teams.',
              indent: false,
            },
            {
              name: 'Led the interview process for new applicants, skillfully assessing technical skills, cultural fit, and potential contributions to the team, resulting in the hiring of top talent who thrived in the company culture.',
              indent: false,
            },
          ],
        },
        {
          name: 'T24 Migration to CLIPP',
          description:
            'Before go-live pricing requests stored in T24 will have to be migrated to CLIPP. ' +
            'Estimated pricing requests were 10,000 in Singapore and 8,000 in Hongkong.',
          involvements: [
            {
              name: 'Translated the mapping document provided by T24 into a format compatible with the CLIPP database, ensuring that data was accurately mapped and ready for migration.',
              indent: false,
            },
            {
              name: 'Developed a custom migration tool using "SpringBoot" and "Hibernate," allowing for efficient, accurate data migration from T24 to CLIPP.',
              indent: false,
            },
            {
              name: 'Collaborated closely with the T24 team to ensure that the migration plan was well-defined and executed flawlessly prior to go-live of CLIPP, minimizing downtime and ensuring business continuity.',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  competitions: [
    {
      name: 'Singapore Airlines Challenge',
      description:
        'Designed and developed a cutting-edge mobile application that leverages augmented reality to provide users with an immersive, interactive experience of browsing Kris Flyers product catalogue. The application showcases products in a visually-engaging way, allowing users to view detailed 3D models and get a better understanding of each items unique features and benefits.',
      video: 'https://www.youtube.com/watch?v=id2Js_BcRb4 ',
      logo: SIAMobile,
      img: SIAArchitecture,
    },
  ],
  technologies: [
    {
      logo: ReactLogo,
      title: 'ReactJS',
      info: 'Built UI in CLIPP using ReactJS',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: KubernetesLogo,
      title: 'Kubernetes',
      info: 'Container orchestration using Kubernetes',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: AwsLogo,
      title: 'AWS',
      info: 'Powered by AWS',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
    {
      logo: FlowableLogo,
      title: 'Flowable.io',
      info: 'CLIPP used embedded flowable for approval workflow',
    },
  ],
};
