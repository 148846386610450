// import {Certification, Education, Experience, Personal, Skill} from '../../../layouts/Resume';
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Paragraph,
  ShadingType,
  TabStopPosition,
  TabStopType,
  TextRun,
} from 'docx';
import {
  Certification,
  Education,
  Experience,
  Language,
  Personal,
  Project,
  SideProject,
  Skill,
} from '../../../layouts/Resume';

export class DocumentCreator {
  create([
    personal,
    skills,
    experiences,
    sideprojects,
    education,
    certfication,
    language,
  ]: any[]): Document {
    const document = new Document({
      styles: {
        default: {
          listParagraph: {
            run: {
              size: 26,
            },
          },
        },
      },
      sections: [
        {
          children: [
            new Paragraph({
              text: personal.name,
              heading: HeadingLevel.TITLE,
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: personal.position,
                  color: '#373232',
                  size: 40,
                }),
              ],
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: personal.description,
                  color: '#000000',
                  size: 28,
                  break: 1,
                }),
              ],
            }),
            this.createNewLine(),
            this.createContactInfo(personal),
            this.createNewLine(),
            this.createHeading('SKILLS'),
            this.createSkillList(skills),
            this.createNewLine(),
            this.createHeading('WORK EXPERIENCE'),
            ...this.createExperiences(experiences),
            this.createNewLine(),
            this.createHeading('SIDE PROJECTS'),
            ...this.createSideProjects(sideprojects),
            this.createNewLine(),
            this.createHeading('EDUCATION'),
            ...this.createEducation(education),
            this.createNewLine(),
            this.createHeading('CERTIFICATES'),
            this.createCertificateList(certfication),
            this.createNewLine(),
            this.createHeading('LANGUAGES'),
            this.createLanguage(language),
            this.createNewLine(),
          ],
        },
      ],
    });

    return document;
  }

  createContactInfo(personal: Personal): Paragraph {
    return new Paragraph({
      shading: {
        type: ShadingType.SOLID,
        color: '#001328',
        fill: '#001328',
      },
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          text: `Email: ${personal.email} | Phone: ${personal.phone} | Location: ${personal.location} | LinkedIn: ${personal.linkedin}`,
          size: 27,
          color: '#ffffff',
        }),
        new TextRun({
          text: `Github: ${personal.github} | Website: ${personal.website} | Location: ${personal.location}`,
          break: 1,
          size: 27,
          color: '#ffffff',
        }),
      ],
    });
  }

  createNewLine(): Paragraph {
    return new Paragraph({
      children: [],
    });
  }

  createHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    });
  }

  createSubHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
    });
  }

  createInstitutionHeader(
    institutionName: string,
    dateText: string
  ): Paragraph {
    return new Paragraph({
      tabStops: [
        {
          type: TabStopType.RIGHT,
          position: TabStopPosition.MAX,
        },
      ],
      children: [
        new TextRun({
          text: institutionName,
          bold: true,
        }),
        new TextRun({
          text: `\t${dateText}`,
          bold: true,
        }),
      ],
    });
  }

  createRoleText(roleText: string): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: roleText,
          italics: true,
        }),
      ],
    });
  }

  createBullet(text: string): Paragraph {
    return new Paragraph({
      text: text,
      bullet: {
        level: 0,
      },
    });
  }

  // tslint:disable-next-line:no-any
  createSkillList(skills: Skill[]): Paragraph {
    let summaryOfSkills = skills.map((skill) => skill.name).join(', ');

    return new Paragraph({
      children: [
        new TextRun({
          text: summaryOfSkills + '.',
          size: 28,
        }),
      ],
    });
  }

  // tslint:disable-next-line:no-any
  createAchivementsList(achivements: any[]): Paragraph[] {
    return achivements.map(
      (achievement) =>
        new Paragraph({
          text: achievement.name,
          bullet: {
            level: 0,
          },
        })
    );
  }

  createInterests(interests: string): Paragraph {
    return new Paragraph({
      children: [new TextRun(interests)],
    });
  }

  createExperiences(experiences: Experience[]): any[] {
    const elements: any[] = [];

    for (let experience of experiences) {
      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: `${experience.position}, ${experience.fromDateResume} - ${experience.toDateResume}`,
              color: '#000000',
              size: 30,
              bold: true,
            }),
            new TextRun({
              text: experience.company,
              color: '#000000',
              size: 30,
              break: 1,
            }),
            new TextRun({
              text: `${experience.country}`,
              color: '#000000',
              size: 30,
              break: 1,
            })
          ],
        })
      );
      elements.push(this.createNewLine());
      elements.push(...this.createModules(experience.projects));
      elements.push(this.createNewLine());
    }

    return elements;
  }

  createModules(projects: Project[]): any[] {
    const elements: any[] = [];

    for (let project of projects) {
      if (project.modules) {
        for (let module of project.modules) {
          if (module.involvements) {
            for (let involvement of module.involvements) {
              elements.push(
                new Paragraph({
                  text: ' ' + involvement.name,
                  heading: HeadingLevel.HEADING_3,
                  bullet: {
                    level: 0, //How deep you want the bullet to be
                  },
                })
              );
            }
          }
        }
      }
    }

    return elements;
  }

  createSideProjects(sideProjects: SideProject[]): any[] {
    const elements: any[] = []
    for (let project of sideProjects) {
      elements.push(
        new Paragraph({
          children: [
            new TextRun({
              text: project.name,
              color: '#000000',
              size: 28,
              bold: true,
            }),
            new TextRun({
              text: ' | ' + project.link,
              color: '#000000',
              size: 28,
            }),
          ],
        })
      );
      elements.push(this.createNewLine());
      elements.push(
        new Paragraph({
          text: project.description,
          heading: HeadingLevel.HEADING_3,
          bullet: {
            level: 0, //How deep you want the bullet to be
          },
        })
      );
      elements.push(this.createNewLine());
    }
    return elements;
  }

  createEducation(education: Education): any[] {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: education.degree,
            color: '#000000',
            size: 30,
            bold: true,
          }),
          new TextRun({
            text: education.schoolName,
            color: '#000000',
            size: 30,
            break: 1,
          }),
          new TextRun({
            text: `${education.startDate} - ${education.endDate}`,
            color: '#000000',
            size: 28,
            break: 1,
          }),
        ],
      }),
    ];
  }

  createCertificateList(certification: Certification[]): Paragraph {
    let summaryOfCert = certification.map((cert) => cert.name).join(', ');

    return new Paragraph({
      children: [
        new TextRun({
          text: summaryOfCert + '.',
          size: 28,
        }),
      ],
    });
  }

  createLanguage(languages: Language[]): Paragraph {
    const children: TextRun[] = [];

    for (let language of languages) {
      children.push(
        new TextRun({
          text: language.name,
          color: '#000000',
          size: 30,
          bold: true,
          break: 1,
        })
      );
      children.push(
        new TextRun({
          text: language.proficiency,
          color: '#000000',
          size: 28,
          italics: true,
          break: 1,
        })
      );
    }

    return new Paragraph({
      children: children,
    });
  }

  splitParagraphIntoBullets(text: string): string[] {
    return text.split('\n\n');
  }

  // tslint:disable-next-line:no-any
  createPositionDateText(
    startDate: any,
    endDate: any,
    isCurrent: boolean
  ): string {
    const startDateText =
      this.getMonthFromInt(startDate.month) + '. ' + startDate.year;
    const endDateText = isCurrent
      ? 'Present'
      : `${this.getMonthFromInt(endDate.month)}. ${endDate.year}`;

    return `${startDateText} - ${endDateText}`;
  }

  getMonthFromInt(value: number): string {
    switch (value) {
      case 1:
        return 'Jan';
      case 2:
        return 'Feb';
      case 3:
        return 'Mar';
      case 4:
        return 'Apr';
      case 5:
        return 'May';
      case 6:
        return 'Jun';
      case 7:
        return 'Jul';
      case 8:
        return 'Aug';
      case 9:
        return 'Sept';
      case 10:
        return 'Oct';
      case 11:
        return 'Nov';
      case 12:
        return 'Dec';
      default:
        return 'N/A';
    }
  }
}
