import { createTheme } from '@mui/material';

const white = '#ffffff';
const green = '#20c168';

const theme = createTheme({
  palette: {
    common: {
      white: `${white}`,
    },
    primary: {
      main: `${white}`,
    },
    secondary: {
      main: `${green}`,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          borderColor: `${white}`,
        },
      },
    },
  },
});

export default theme;
