import React from 'react';
import './App.css';
import { AppBar } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import theme from './Theme';
import ResponsiveToolbar from './toolbar/ResponsiveToolbar';
import Home from './components/Home/Home';
import { Box } from '@mui/material';

const App = () => {
  const actions = {};
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <ResponsiveToolbar actions={actions} />
          </AppBar>
        </Box>
        <Home actions={actions} />
      </div>
    </ThemeProvider>
  );
};

export default App;
