import { Avatar, Chip, Grid } from '@mui/material';
import React from 'react';
import Item from '../../../components/Item/Item';
import { Technology } from '../../JobDetail/web-view/components/WebTechnologies';

const FooterBadgeMobile = ({
  role,
  technologies,
}: {
  role: string;
  technologies: Array<Technology>;
}) => {
  return (
    <Grid container gap={1} spacing={2} style={{ height: '200px' }}>
      <Grid item xs={12}>
        <Item style={{ height: '100%' }}>
          <strong>Position:</strong> <u>{role}</u>
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item style={{ height: '100%' }}>
          <Grid
            alignContent={'center'}
            alignItems={'center'}
            container
            gap={1}
            justifyContent={'center'}
          >
            {technologies?.map((technology) => (
              <Grid item key={technology.title} xs={3}>
                <Chip
                  avatar={<Avatar src={technology.logo} />}
                  color={'primary'}
                  key={technology.title}
                  label={technology.title}
                  style={{ fontSize: '0.8rem' }}
                />
              </Grid>
            ))}
          </Grid>
        </Item>
      </Grid>
    </Grid>
  );
};

export default FooterBadgeMobile;
