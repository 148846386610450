import {
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
  Grid,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Item from '../../../components/Item/Item';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: '100%',
      fontSize: '0.6rem',
    },
  })
);

const BodyList = ({ list }: { list: Array<string | number> }) => {
  const classes = useStyles();
  return (
    <Grid container gap={1} spacing={2}>
      <Grid item style={{ height: '100%' }} xs={12}>
        <Item style={{ height: '100%' }}>
          <List
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            component="nav"
          >
            {list.map((val) => (
              <List component="div" disablePadding key={val}>
                <ListItem>
                  <ListItemIcon>
                    <StarBorderIcon />
                  </ListItemIcon>
                  <ListItemText primary={val} />
                </ListItem>
              </List>
            ))}
          </List>
        </Item>
      </Grid>
    </Grid>
  );
};

export default BodyList;
