import React from 'react';
import { ReactNode } from 'react';

const Page = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        height: '100%',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'space-between',
        backgroundColor: 'lightblue',
      }}
    >
      {children}
    </div>
  );
};

export default Page;
