import { Grid } from '@mui/material';
import React from 'react';
import Header from '../component/Header/Header';
import Page from '../Page';
import { Module, Project } from '../../layouts/Resume';
import { Summary } from '../JobSummaryPage/JobSummaryPage';
// import InvolvementList from '../component/BodyList/BodyList';
import Body from '../component/Body/Body';
import BodyList from '../component/BodyList/BodyList';

const JobResponsibilityPage = ({
  summary,
  project,
  module,
}: {
  summary: Summary;
  project: Project;
  module: Module;
}) => {
  return (
    <Page>
      <Grid container gap={1} spacing={2}>
        <Grid item xs={12}>
          <Header
            color={project.color}
            header={'Responsibilities'}
            logoHeight={summary.logoHeight}
            logoPicture={summary.picture}
            subHeader={project.client}
          />
        </Grid>
        <Grid item xs={12}>
          <Body fontSize={'1.3rem'} header={module.name} logo={project.logo} />
        </Grid>
        {module.involvements && (
          <Grid item xs={12}>
            <BodyList list={module?.involvements?.map((inv) => inv.name)} />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default JobResponsibilityPage;
