import React from 'react';
import SkillChartMobile from './SkillChartMobile';

const JobSkillsMobile = ({ skills }: { skills: any[] }) => {
  return (
    <div
      className="cover-page"
      style={{
        backgroundColor: '#eeebe6',
        padding: '0px 0px',
        margin: '0px 0px',
      }}
    >
      <SkillChartMobile skills={skills} />
    </div>
  );
};

export default JobSkillsMobile;
