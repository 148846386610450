import React from 'react';
import { Grid } from '@mui/material';
import Item from '../../../components/Item/Item';
import CompanyLogo from '../../../components/Home/components/CompanyLogo';
import CustomLabel from '../../../components/Home/components/CustomLabel';

type Props = {
  logoHeight?: string;
  logoPicture: string;
  bgColor?: string;
  header: string;
  color?: string;
  subHeader: string;
};

const HeaderMobile = ({
  logoHeight,
  logoPicture,
  color,
  header,
  bgColor,
  subHeader,
}: Props) => {
  return (
    <Grid container gap={1} spacing={2}>
      <Grid item xs={4}>
        <Item style={{ height: '100%' }}>
          <CompanyLogo
            alt={`${header} logo`}
            height={logoHeight}
            img={logoPicture}
          />
        </Item>
      </Grid>
      <Grid item xs={7}>
        <Item style={{ height: '100%' }}>
          <CustomLabel
            backgroundColor={bgColor}
            header={header}
            headerColor={color}
            subColor={color}
            subHeader={subHeader}
          />
        </Item>
      </Grid>
    </Grid>
  );
};

export default HeaderMobile;
