import { Experience } from '../../layouts/Resume';
import zuhlke from '../../../experiences/zuhlke';
import macquarie from '../../../experiences/macquarie';
import phoenix from '../../../experiences/phoenix';
import { skills1 } from '../../../experiences/skills1';
import goldenway from '../../../experiences/goldenway';
import rcg from '../../../experiences/rcg';
import ust from '../../../experiences/ust';
import kforce from '../../../experiences/kforce';
import dhl from '../../../experiences/dhl';
import ncsph from '../../../experiences/ncsph';
import { skills2 } from '../../../experiences/skills2';
import ncs from '../../../experiences/ncs';
import nova from '../../../experiences/nova';
import { skills3 } from '../../../experiences/skills3';
import tpicap from '../../../experiences/tpicap';

export const listOfExperiences: Experience[] = [
  tpicap,
  zuhlke,
  ncs,
  macquarie,
  dhl,
  rcg,
  nova,
  ncsph,
  ust,
  kforce,
  goldenway,
  phoenix,
];
export const listOfSkills = skills1.concat(skills2).concat(skills3);
export const listOfSkillsArr = [skills1, skills2, skills3];
export const combinedListOfSkillsArr = [...skills1, ...skills2, ...skills3];
