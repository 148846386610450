export const personal = (total) => {
  return {
    name: 'Jesus Emmanuel Dayo',
    phone: '+65 84307286',
    location: 'Singapore',
    description: `As an experienced Full Stack Software Developer with ${total} years of hands-on experience
     in web development, I have honed my skills in both front-end and back-end development. My expertise includes solution design, 
    leadership, and improving the development pipeline to increase efficiency and productivity.`,
    email: 'jdayojr@gmail.com',
    website: 'www.jeddayo.com',
    linkedin: 'https://www.linkedin.com/in/jesus-emmanuel-dayo-6435a913',
    github: 'https://github.com/jesus-dayo',
    position: 'Lead/Senior Full Stack Developer (Java)',
  };
};
