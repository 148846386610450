import DHLLogo from '../assets/images/logo/company/dhl-logo.png';
import MalaysiaFlag from '../assets/images/flags/malaysia-flag.jpeg';
import DHLOffice from '../assets/images/dhl/dhl-office.jpeg';
import DHLExpressLogo from '../assets/images/dhl/dhl-express-logo.png';
import AngularLogo from '../assets/images/logo/tech/angularjs.png';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import SpringBatchLogo from '../assets/images/logo/tech/spring-batch.png';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import CAMSArchitecture from '../assets/images/dhl/cams-architecture.png';

export default {
  backgroundColor: '#987606',
  subBackgroundColor: '#cfac80',
  color: '#ffffff',
  company: 'DHL IT Services',
  position: 'Java Technical Lead',
  fromDate: 'Mar-01-2015',
  fromDateResume: '03/2015',
  toDate: 'Sep-30-2016',
  toDateResume: '09/2016',
  picture: DHLLogo,
  description:
    'DHL IT Services in Cyberjaya, Malaysia is dedicated to delivering world-class software solutions that enhance the productivity and effectiveness of both front office and back office teams. Our cutting-edge software tools empower users to streamline their day-to-day workflow.',
  page: '/resume/job-detail',
  country: 'Malaysia',
  flagImg: MalaysiaFlag,
  officeImg: DHLOffice,
  pageNum: 22,
  projects: [
    {
      client: 'DHL Express',
      color: '#de8704',
      name: 'Contract Management System',
      role: 'Java Team Lead',
      description: `At DHL, we recognize the importance of effective contract management for our third-party logistics providers. That's why we've developed an innovative on-premises web application called the Contract Management System, which is designed specifically to meet the needs of our back office users. The system serves as a centralized database to store all relevant information about our third-party logistic providers, 
        from billing and pricing to service delivery requirements and performance metrics.
        `,
      logo: DHLExpressLogo,
      img: CAMSArchitecture,
      architecture: CAMSArchitecture,
      modules: [
        {
          name: 'Contract Management System Development (Part 1)',
          description: 'Lead developer for CAMS',
          involvements: [
            {
              name: 'Translated complex requirements documents into comprehensive technical specifications with ease and precision',
              indent: false,
            },
            {
              name: 'Developed robust, high-performance Java components using cutting-edge frameworks like Spring Boot, Hibernate and Spring Batch for maximum efficiency and scalability',
              indent: false,
            },
            {
              name: 'Crafted visually stunning, feature-rich GUI components utilizing AngularJS to deliver an unparalleled user experience',
              indent: false,
            },
            {
              name: 'Engineered a highly efficient development pipeline that leveraged powerful tools like Jenkins, Bitbucket and CheckStyle to continuously improve software quality and speed up the release process',
              indent: false,
            },
            {
              name: 'Performed rigorous code reviews of pull requests from other developers on Bitbucket to maintain a high level of code quality throughout the development lifecycle',
              indent: false,
            },
          ],
        },
        {
          name: 'Contract Management System Development (Part 2)',
          description: 'Lead developer for CAMS',
          involvements: [
            {
              name: 'Led the interview process for new applicants joining DHL, ensuring only the most talented and qualified candidates were selected to join our world-class team',
              indent: false,
            },
            {
              name: 'Expertly led, coached and mentored a highly skilled team of 4 developers and 2 QA specialists to achieve peak performance and excellence in software development',
              indent: false,
            },
            {
              name: 'Maintained seamless collaboration with the Project Manager to accurately set timelines and ensure optimal resource allocation for timely project delivery',
              indent: false,
            },
            {
              name: 'Worked closely with the Technical Architect to design and develop secure, scalable and high-performance solutions that seamlessly integrate with external systems and interfaces',
              indent: false,
            },
            {
              name: 'Collaborated with the Database Administrator to ensure adherence to company standards in database design and management, ensuring maximum data reliability and security for the organization',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: AngularLogo,
      title: 'AngularJS',
      info: 'Built UI in CAMS using AngularJS',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
    {
      logo: SpringBatchLogo,
      title: 'Spring Batch',
      info: 'Used for generating heavy reports',
    },
  ],
};
