import { Grid } from '@mui/material';
import React from 'react';
import Item from '../../../components/Item/Item';

const Footer = ({ description }: { description: string }) => {
  return (
    <Grid container gap={1} spacing={2}>
      <Grid item style={{ height: '200px' }} xs={12}>
        <Item style={{ height: '100%' }}>{description}</Item>
      </Grid>
    </Grid>
  );
};

export default Footer;
