import CompanyLogo from '../assets/images/goldenway/goldenway-logo.png';
import Flag from '../assets/images/flags/philippine-flag.jpg';
import Office from '../assets/images/goldenway/rcbc-plaza.jpeg';
import CasinoLogo from '../assets/images/goldenway/casino-logo.png';
import GoldenwayArchitecture from '../assets/images/goldenway/goldenway-architecture.png';
import JQueryLogo from '../assets/images/logo/tech/jquery-logo.gif';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import Struts from '../assets/images/logo/tech/struts2.jpeg';

export default {
  backgroundColor: '#d6b606',
  subBackgroundColor: '#cfcf23',
  color: '#ffffff',
  company: 'Goldenway International',
  position: 'Junior Java Programmer',
  fromDate: 'May-01-2010',
  fromDateResume: '05/2010',
  toDate: 'Feb-28-2011',
  toDateResume: '02/2011',
  picture: CompanyLogo,
  description:
    'Goldenway International is a China based company for which its business is mainly online Casino. ' +
    ' Their office in the Phillipines is within Makati City. Currently, the company name has renamed itself to "Intevalue Services."',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: Flag,
  officeImg: Office,
  pageNum: 44,
  projects: [
    {
      client: 'Goldenway Online Casino',
      name: 'Goldenway Online Casino',
      role: 'Junior Java Programmer',
      color: '#092847',
      description:
        'Main users of the applications are online casino players around China. ' +
        'The online casino website calls an external service which provides user authentication. ' +
        'The web application integrated with a China based payment gateway for client deposits and withdrawals.' +
        ' The website also offers live games with an actual dealer in front of a camera. ',
      logo: CasinoLogo,
      img: GoldenwayArchitecture,
      architecture: GoldenwayArchitecture,
      modules: [
        {
          name: 'Goldenway Online Casino',
          description: 'Core developer',
          involvements: [
            {
              name: 'Spearheaded the development effort as a core developer in charge of building both the backend and GUI components for an online casino platform, ensuring top-notch functionality and user experience',
              indent: false,
            },
            {
              name: 'Collaborated with the Web Designer to translate designs into seamlessly integrated HTML code, adding extensive interactivity using JQuery to create a dynamic and engaging interface',
              indent: false,
            },
            {
              name: 'Created comprehensive unit test cases using cutting-edge tools such as JUnit and Mockito, ensuring top-notch performance and reliability of Java components built',
              indent: false,
            },
            {
              name: 'Played a key role in constantly improving the software development process by collaborating closely with the team to identify areas for improvement and implement best practices',
              indent: false,
            },
          ],
        },
        {
          name: 'Goldenway Online Casino (Part 2)',
          description: 'Core developer',
          involvements: [
            {
              name: 'Developed and implemented automated testing using Selenium Web Driver, reducing testing time and increasing overall product quality',
              indent: false,
            },
            {
              name: 'Ensured unparalleled quality in all code developed and played a vital role in User Acceptance Testing (UAT), providing expert bug fixes when necessary to keep the project on track and ensure seamless delivery',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: Struts,
      title: 'Struts 2',
      info: 'Built backend services in Struts',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
    {
      logo: JQueryLogo,
      title: 'JQuery',
      info: 'UI is via JQuery',
    },
  ],
};
