import React from 'react';

const CompanyLogo = ({
  alt,
  img,
  height,
}: {
  alt: string;
  img: any;
  height?: string;
}) => {
  return (
    <img
      alt={alt}
      src={img}
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        height: '100%' || height,
        width: '100%',
      }}
    />
  );
};

export default CompanyLogo;
