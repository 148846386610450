import NCSLogo from '../assets/images/logo/company/ncs-logo.jpg';
import SingaporeFlag from '../assets/images/flags/singapore-flag.png';
import NCSSingaporeOffice from '../assets/images/ncs/ncs-office.jpg';
import LTALogo from '../assets/images/clients/lta-logo.jpeg';
import ERP2 from '../assets/images/projects/erp2/erp2-arc.png';
import OracleWeblogic from '../assets/images/logo/tech/oracle-webloguc-logo.jpg';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import AngularJs from '../assets/images/logo/tech/angularjs.png';

export default {
  color: '#ffffff',
  backgroundColor: '#376D8A',
  subBackgroundColor: '#5fa8d0',
  company: 'NCS Pte Ltd',
  position: 'Senior Software Engineer',
  fromDate: 'Oct-01-2018',
  fromDateResume: '10/2018',
  toDate: 'Jun-25-2019',
  toDateResume: '06/2019',
  picture: NCSLogo,
  description:
    'NCS PTE LTD in Singapore is located at Ang Mo Kio and is a well known IT service provider.\n' +
    'Majority of their clients are government entities on different Asian countries.',
  page: '/resume/job-detail',
  country: 'Singapore',
  flagImg: SingaporeFlag,
  officeImg: NCSSingaporeOffice,
  pageNum: 14,
  projects: [
    {
      client: 'Land Transportation Authority of Singapore',
      name: 'Electronic Road Pricing (ERP2) System',
      role: 'Senior Software Engineer',
      color: '#092847',
      description:
        'ERP2 is a new application in LTA that would replace its existing ERP1.' +
        ' ERP2 removes the dependencies on Charging Barriers around the streets of Singapore and will be completely replaced ' +
        ' by gps + satellites and cameras.' +
        ' In order to support this, vehicles in Singapore will have an "On Boarding Unit" attached.',
      logo: LTALogo,
      architecture: ERP2,
      img: ERP2,
      logoWidth: '150px',
      modules: [
        {
          name: 'Payment Tariff Module',
          description: 'Core developer',
          involvements: [
            {
              name: 'Developed a highly effective batch job to detect and charge missing transactions, reducing revenue losses and improving overall financial performance',
              indent: false,
            },
            {
              name: 'Created a comprehensive monthly report using Crystal Reports to display all missing transactions, highlighting areas for improvement and enabling targeted problem-solving efforts',
              indent: false,
            },
            {
              name: 'Demonstrated exceptional technical expertise by developing new and innovative features in the tariff table, including advanced price progression based on time of day',
              indent: false,
            },
            {
              name: 'Collaborated closely with both Technical Architect and Team Lead to effectively understand business requirements and align development efforts with strategic goals',
              indent: false,
            },
            {
              name: 'Visited LTA Tuas and created detailed test reports for actual testing of OBU units in cars and ERP2 camera accuracy, demonstrating an unwavering commitment to excellence and ensuring optimal product functionality',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: AngularJs,
      title: 'AngularJS',
      info: 'Built UI in ERP2 using AngularJs',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: OracleWeblogic,
      title: 'Oracle Web Logic',
      info: 'App Server',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
  ],
};
