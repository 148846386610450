export const skills1 = [
  { name: 'Java', years: 12, color: '#1188e3' },
  { name: 'ReactJS', years: 6, color: '#11e345' },
  { name: 'MySQL', years: 7, color: '#11e3d1' },
  { name: 'NodeJS', years: 2, color: '#a4e311' },
  { name: 'TypeScript', years: 2, color: '#e31165' },
  { name: 'Flowable', years: 1, color: '#e39611' },
  { name: 'SpringBoot', years: 7, color: '#436e5a' },
  { name: 'AWS', years: 3, color: '#e37311' },
];
