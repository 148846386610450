import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { isMobileOnly } from 'react-device-detect';

const useStyles = makeStyles(() =>
  createStyles({
    companyName: {
      fontSize: !isMobileOnly ? '1.6rem' : '1.1rem',
      fontFamily:
        '"SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif',
    },
    dates: {
      fontSize: !isMobileOnly ? '1.3rem' : '1rem',
      fontFamily:
        '"SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif',
    },
  })
);

const CustomLabel = ({
  headerColor,
  headerFontSize,
  header,
  subHeader,
  backgroundColor,
  subColor,
}: {
  headerColor?: string;
  header: string;
  headerFontSize?: string;
  subHeader: string;
  backgroundColor?: string;
  subColor?: string;
}) => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor, height: '100%', width: '100%' }}>
      <div
        className={classes.companyName}
        style={{ color: headerColor, fontSize: headerFontSize }}
      >
        {header}
      </div>
      <div className={classes.dates} style={{ color: subColor }}>
        {subHeader}
      </div>
    </div>
  );
};

export default CustomLabel;
