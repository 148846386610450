import { Experience } from '../layouts/Resume';
import { differenceInMonths, parse } from 'date-fns';

export const computeTotalYears = (experiences: Experience[]) => {
  let total = experiences
    .map((e) => {
      let startDate: Date = parse(e.fromDate, 'MMM-dd-yyyy', new Date());
      let toDate: Date = new Date();

      if (e.toDateResume !== 'present') {
        toDate = parse(e.toDate, 'MMM-dd-yyyy', new Date());
      }

      let difference = differenceInMonths(toDate, startDate) / 12;
      return difference;
    })
    .reduce((prev: number, next: number) => prev + next, 0);
  return Math.round(total * 10) / 10;
};
