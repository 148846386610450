import CompanyLogo from '../assets/images/phoenix-one/phoenix-logo.jpeg';
import Flag from '../assets/images/flags/philippine-flag.jpg';
import Office from '../assets/images/phoenix-one/phoenix-office.jpeg';
import TeacherLogo from '../assets/images/phoenix-one/teach_logo.jpg';
import TeachJquery from '../assets/images/phoenix-one/jquery_teach.JPG';
import JQueryLogo from '../assets/images/logo/tech/jquery-logo.gif';
import MySQL from '../assets/images/logo/tech/mysql_logo.jpeg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import Struts from '../assets/images/logo/tech/struts2.jpeg';

export default {
  backgroundColor: '#910404',
  subBackgroundColor: '#941919',
  color: '#ffffff',
  company: 'Phoenix One',
  position: 'Java Programmer Trainee',
  fromDate: 'Apr-01-2009',
  fromDateResume: '04/2009',
  toDate: 'Apr-01-2010',
  toDateResume: '04/2010',
  picture: CompanyLogo,
  description:
    'Phoenix One Knowledge Solutions is an IT training center for companies or individuals who aspire to improve on their field. ' +
    ' They sell different IT bootcamps to large software companies to enhance the skills of their people.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: Flag,
  officeImg: Office,
  pageNum: 47,
  projects: [
    {
      client: 'Safeway',
      name: 'Teaching and In-house Development',
      role: 'Junior Java Programmer',
      description:
        ' I started working in Phoenix One as an IT Sales Consultant and at the same time worked as a Java Programmer Trainee. ' +
        ' When I attained the actual work experience, I later on teach and became a trainer myself in Phoenix One. ' +
        ' Picture above was when I was training JQuery to IT professionals from a company called Safeway.',
      color: '#092847',
      logo: TeacherLogo,
      img: TeachJquery,
      architecture: TeachJquery,
      modules: [
        {
          name: 'Inquiry Registration',
          description: 'Core developer',
          involvements: [
            {
              name: 'Developed back-end and GUI components for Phoenix One inquiry registration using JSP, Spring Framework, and Hibernate as Core Developer.',
              indent: false,
            },
            {
              name: 'Collaborated with front-office users and stakeholders to gather requirements and feedback to ensure product met business needs.',
              indent: false,
            },
          ],
        },
        {
          name: 'Teaching Exploration',
          description: 'Teacher',
          involvements: [
            {
              name: 'Conducted extensive JQuery training sessions for Safeway in 2014',
              indent: false,
            },
            {
              name: 'Provided comprehensive HTML and CSS training in 2013 to assist an individual transition into web development',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: Struts,
      title: 'Struts 2',
      info: 'Built backend services in Struts',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: MySQL,
      title: 'MySQL Database',
      info: 'Worked on MySQL during my training',
    },
    {
      logo: JQueryLogo,
      title: 'JQuery',
      info: 'UI is via JQuery',
    },
  ],
};
