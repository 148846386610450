import NovaLogo from '../assets/images/nova/nova-logo.jpeg';
import PhilippineFlag from '../assets/images/flags/philippine-flag.jpg';
import NovaOffice from '../assets/images/nova/nova-office.jpeg';
import LTALogo from '../assets/images/nova/jpd.png';
import LTDArchitecture from '../assets/images/nova/ltd-architecture.png';
import OracleWeblogic from '../assets/images/logo/tech/oracle-webloguc-logo.jpg';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import MyBatisLogo from '../assets/images/logo/tech/my-batis.png';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import JQuery from '../assets/images/logo/tech/jquery-logo.gif';

export default {
  color: '#ffffff',
  backgroundColor: '#860b2f',
  subBackgroundColor: '#b34c54',
  company: 'Nova Solutions Inc',
  position: 'Java Team Lead',
  fromDate: 'Nov-01-2013',
  fromDateResume: '11/2013',
  toDate: 'Feb-28-2014',
  toDateResume: '02/2014',
  picture: NovaLogo,
  description:
    'Nova Solutions is an IT Service Provider located on different locations in ASIA. ' +
    ' The office in the Philippines is situated at Corporate Center in Pasig City.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: PhilippineFlag,
  officeImg: NovaOffice,
  pageNum: 31,
  projects: [
    {
      client: 'Land Transportation of Brunei',
      name: 'Licensing System',
      role: 'Java Team Lead',
      description:
        'Land transportation system of Brunei is decommissioning the old paper license and converting it all to card based with digital chip.' +
        ' The licensing system will allow application to submit a license application form via the licensing website. ' +
        ' The licensing system also integrates with the card printer for front office users to print the license card. ',
      color: '#092847',
      logo: LTALogo,
      logoBg: '#000a64',
      architecture: LTDArchitecture,
      img: LTDArchitecture,
      logoWidth: '150px',
      modules: [
        {
          name: 'Licensing System',
          description: 'Java Lead Developer',
          involvements: [
            {
              name: 'Successfully enhanced and improved the current development process, resulting in increased efficiency, productivity, and innovation',
              indent: false,
            },
            {
              name: 'Expertly assigned and prioritized tasks to developers based on a deep understanding of their skills and abilities, ensuring seamless collaboration and task completion at every stage of development',
              indent: false,
            },
            {
              name: 'Collaborated with the client in LTA Brunei and gained valuable insights into their existing process and pain points relating to paper licenses, contributing to the development of new and innovative solutions',
              indent: false,
            },
            {
              name: 'Developed an innovative new feature allowing for the collation of new license applications and sending them to the back office for verification, streamlining the overall licensing process',
              indent: false,
            },
          ],
        },
        {
          name: 'Licensing System (Part 2)',
          description: 'Java Lead Developer',
          involvements: [
            {
              name: 'Successfully optimized existing SQL queries written in MyBatis using Oracle SQL explain plan, resulting in faster query execution times and improved performance',
              indent: false,
            },
            {
              name: 'Demonstrated exceptional leadership skills by conducting thorough and insightful interviews for potential new applicants, selecting only the best candidates who were able to contribute to the success of the NCS team',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: JQuery,
      title: 'JQuery',
      info: 'Built UI in JQuery',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: OracleWeblogic,
      title: 'Oracle Web Logic',
      info: 'App Server',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
    {
      logo: MyBatisLogo,
      title: 'MyBatis',
      info: 'ORM Tool',
    },
  ],
};
