import React from 'react';
import { Container } from '@material-ui/core';
import { isMobileOnly } from 'react-device-detect';
import WebBook from './WebBook';
import MobileBook from './MobileBook';
import bg_web from '../../../assets/images/bg_web.jpg';

const Home = ({ actions }: { actions: any }) => {
  return (
    <>
      {!isMobileOnly && (
        <div
          style={{
            background: `url(${bg_web}) no-repeat top center fixed`,
            backgroundSize: 'cover',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          <Container maxWidth="xl" style={{ padding: '9px 0px' }}>
            <WebBook actions={actions} />
          </Container>
        </div>
      )}
      {isMobileOnly && <MobileBook actions={actions} />}
    </>
  );
};

export default Home;
