import React from 'react';
import { Button } from '@material-ui/core';
import { DocumentCreator } from '../components/Home/components/cv-generator';
import { personal } from '../../experiences/personal';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { education } from '../../experiences/education';
import { language } from '../../experiences/language';
import { certification } from '../../experiences/certification';
import { sideprojects } from '../../experiences/sideproject';
import { listOfExperiences, listOfSkills } from '../components/Home/inputs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import { computeTotalYears } from '../util/util';

export default function WebMenu({ actions }: { actions: any }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const experiences = listOfExperiences;
  const skills = listOfSkills;

  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHome = () => {
    turnToPage(0);
    handleClose();
  };

  const downloadResume = () => {
    const documentCreator = new DocumentCreator();
    const doc = documentCreator.create([
      personal(computeTotalYears(experiences)),
      skills,
      experiences,
      sideprojects,
      education,
      certification,
      language,
    ]);
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'cv_jesusemmanueldayo.docx');
    });
  };

  const turnToPage = (pageNumber: number) => {
    actions.turnToPage(pageNumber);
    handleClose();
  };

  return (
    <div>
      <Button
        color="inherit"
        onClick={() => handleHome()}
        style={{ textTransform: 'none' }}
      >
        About Me
      </Button>
      <Button
        color="inherit"
        onClick={() => turnToPage(1)}
        style={{ textTransform: 'none' }}
      >
        Certifications
      </Button>
      <Button
        color="inherit"
        onClick={() => turnToPage(2)}
        style={{ textTransform: 'none' }}
      >
        Skills
      </Button>
      <Button
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ textTransform: 'none' }}
      >
        Experience
      </Button>
      <Menu
        TransitionComponent={Fade}
        anchorEl={anchorEl}
        id="fade-menu"
        keepMounted
        onClose={handleClose}
        open={open}
      >
        {experiences.map((experience) => (
          <MenuItem
            color="inherit"
            key={experience.company}
            onClick={() => turnToPage(experience.pageNum)}
          >
            {experience.company}
          </MenuItem>
        ))}
      </Menu>
      <Button
        color="inherit"
        onClick={() => turnToPage(51)}
        style={{ textTransform: 'none' }}
      >
        Contact Me
      </Button>
      <Button
        color="inherit"
        onClick={() => downloadResume()}
        style={{ textTransform: 'none' }}
      >
        Download Resume
      </Button>
    </div>
  );
}
