import KForceLogo from '../assets/images/kforce/kforce-logo.png';
import PhilippineFlag from '../assets/images/flags/philippine-flag.jpg';
import KForceOffice from '../assets/images/kforce/kforce-office.jpeg';
import WillisLogo from '../assets/images/kforce/willis-logo.png';
import WillisArchitecture from '../assets/images/kforce/willis-architecture.png';
import OracleSQL from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import Javascript from '../assets/images/logo/tech/javascript.png';

export default {
  color: '#ffffff',
  backgroundColor: '#cf5b38',
  subBackgroundColor: '#bf7747',
  company: 'KForce Global Solutions',
  position: 'Java Programmer Analyst',
  fromDate: 'Mar-01-2011',
  fromDateResume: '03/2011',
  toDate: 'Feb-28-2012',
  toDateResume: '02/2012',
  picture: KForceLogo,
  description:
    'KForce Global Solutions in the Philippines is located at Makati City and is an IT service provider, unfortunately the company has already closed down.\n' +
    'Most of their clients are U.S. based.',
  page: '/resume/job-detail',
  country: 'Philippines',
  flagImg: PhilippineFlag,
  officeImg: KForceOffice,
  pageNum: 41,
  projects: [
    {
      client: 'Willis Insurance',
      name: 'Willis Insurance Heat Map',
      role: 'Java Programmer Analyst',
      description:
        'Willis Insurance Heat Map, provides a holistic overview of different states in the U.S and its risk factor based on the current ' +
        ' weather and events. Additionally , the application also shows a forecasted risks on those states by displaying a rich map UI.' +
        ' The UI for this project is mostly controlled by whats in the database, so most of the changes would require intensive PLSQL knowledge.',
      color: '#092847',
      logo: WillisLogo,
      architecture: WillisArchitecture,
      img: WillisArchitecture,
      logoWidth: '150px',
      modules: [
        {
          name: 'Willis Insurance',
          description: 'Core developer',
          involvements: [
            {
              name: 'Led the charge in developing a proof of concept for a heatmap feature in the Willis Insurance app using Google Maps API, showcasing the benefits of enhanced data visualization capabilities',
              indent: false,
            },
            {
              name: 'Significantly improved user experience by enhancing the GUI for the Willis Insurance app, leveraging cutting edge technologies and design principles ',
              indent: false,
            },
            {
              name: 'Ensured unparalleled quality by thoroughly testing newly developed components in the Willis Insurance app, guaranteeing performance and reliability',
              indent: false,
            },
            {
              name: 'Formulated and executed effective SQL scripts to enhance the content of the Willis Insurance app, driving better engagement and higher customer satisfaction',
              indent: false,
            },
            {
              name: 'Developed robust backend components using Java and JSP in the existing Wills Insurance app, ensuring seamless integration and top-notch functionality from end to end',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: Javascript,
      title: 'Javascript',
      info: 'Built UI in MPA',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleSQL,
      title: 'Oracle / PLSQL ',
      info: 'database is in Oracle',
    },
  ],
};
