import { Grid } from '@mui/material';
import React from 'react';
import Page from '../Page';
import { Summary } from '../JobSummaryPage/JobSummaryPage';
import { Project } from '../../layouts/Resume';
// import ProjectSummary from '../../components/Home/components/ProjectSummary';
import Header from '../component/Header/Header';
import Body from '../component/Body/Body';
import Footer from '../component/Footer/Footer';

const JobProjectPage = ({
  summary,
  project,
}: {
  summary: Summary;
  project: Project;
}) => {
  return (
    <Page>
      <Grid container gap={1} spacing={2}>
        <Grid item xs={12}>
          <Header
            color={project.color}
            header={'Project'}
            logoHeight={summary.logoHeight}
            logoPicture={summary.picture}
            subHeader={project.client}
          />
        </Grid>
        <Grid item xs={12}>
          <Body
            fontSize={'1.4rem'}
            header={project.name}
            image={project.architecture}
            logo={project.logo}
          />
        </Grid>
        <Grid item xs={12}>
          <Footer description={project.description} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default JobProjectPage;
