export const language = [{
  name: 'English',
  proficiency: 'Native Proficiency' 
},{
  name: 'Tagalog',
  proficiency: 'Native Proficiency'
},
{
  name: 'German',
  proficiency: 'Beginner'
}];
