import { Grid } from '@mui/material';
import React from 'react';
import Item from '../../../components/Item/Item';

type Props = {
  header: string;
  logo: string;
  image?: string;
  fontSize?: string;
};

const Body = ({ header, logo, image, fontSize }: Props) => {
  return (
    <Grid container gap={1} spacing={2}>
      <Grid item style={{ height: '100px' }} xs={12}>
        <Item style={{ height: '100%' }}>
          <Grid container gap={1}>
            <Grid item xs={2}>
              <div style={{ height: '100px' }}>
                <img alt={'logo'} height="80px" src={logo} width="100%" />
              </div>
            </Grid>
            <Grid item xs={9}>
              <span style={{ fontSize }}>{header}</span>
            </Grid>
          </Grid>
        </Item>
      </Grid>
      {image && (
        <Grid item style={{ height: '300px' }} xs={12}>
          <Item style={{ height: '100%' }}>
            <img alt={'image'} height="100%" src={image} width="100%" />
          </Item>
        </Grid>
      )}
    </Grid>
  );
};

export default Body;
