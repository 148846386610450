/* eslint-disable react/no-multi-comp */
import React, { ForwardedRef, PropsWithChildren, useRef } from 'react';
import HTMLFlipBook from 'react-pageflip';
import ProfilePage from '../../../assets/images/home/profile-page.png';
import CertPage from '../../../assets/images/home/cert-page.jpg';
import Contact from '../../../assets/images/home/contactme.jpg';
import zuhlke from '../../../experiences/zuhlke';
import ncs from '../../../experiences/ncs';
import macquarie from '../../../experiences/macquarie';
import { Experience } from '../../layouts/Resume';
import { isTablet } from 'react-device-detect';
import dhl from '../../../experiences/dhl';
import rcg from '../../../experiences/rcg';
import nova from '../../../experiences/nova';
import ncsph from '../../../experiences/ncsph';
import ust from '../../../experiences/ust';
import kforce from '../../../experiences/kforce';
import goldenway from '../../../experiences/goldenway';
import phoenix from '../../../experiences/phoenix';
import JobSkills from './components/JobSkills';
import { listOfSkillsArr } from './inputs';
import { computeTotalYears } from '../../util/util';
import JobSummaryPage from '../../pages/JobSummaryPage/JobSummaryPage';
import JobProjectPage from '../../pages/JobProjectPage/JobProjectPage';
import JobResponsibilityPage from '../../pages/JobResponsibilityPage/JobResponsibiltyPage';
import tpicap from '../../../experiences/tpicap';

// eslint-disable-next-line react/display-name
const Page = React.forwardRef(
  (props: PropsWithChildren<any>, ref: ForwardedRef<any>) => {
    return (
      <div className="demoPage" ref={ref}>
        {props.children}
        {props.number && (
          <span
            style={{ position: 'relative', bottom: '25px', textAlign: 'left' }}
          >
            Page {props.number} of {props.total}
          </span>
        )}
      </div>
    );
  }
);

const WebBook = ({ actions }: { actions: any }) => {
  const ref = useRef<HTMLElement>(null);
  const experiences: Experience[] = [
    tpicap,
    zuhlke,
    ncs,
    macquarie,
    dhl,
    rcg,
    nova,
    ncsph,
    ust,
    kforce,
    goldenway,
    phoenix,
  ];
  const skills = listOfSkillsArr;
  let pages: any[] = [];
  for (let x = 0; x < experiences.length; x++) {
    const experience = experiences[x];
    pages.push(<JobSummaryPage {...experience} />);
    for (let y = 0; y < experience?.projects?.length; y++) {
      const project = experience?.projects[y];
      pages.push(
        <JobProjectPage
          key={project.name}
          project={project}
          summary={experience}
        />
      );
      if (project.modules && project.modules.length > 0) {
        project.modules.forEach((module) =>
          pages.push(
            <JobResponsibilityPage
              module={module}
              project={project}
              summary={experience}
            />
          )
        );
      }
    }
  }

  const turnToPage = (pageNumber: number) => {
    if (null !== ref.current) {
      // @ts-ignore
      ref.current.pageFlip().turnToPage(pageNumber);
    }
  };

  actions.turnToPage = turnToPage;

  // @ts-ignore
  return (
    <>
      {/*
      // @ts-ignore */}
      <HTMLFlipBook
        className="demo-book"
        height={800}
        maxHeight={1533}
        maxWidth={isTablet ? 900 : 1000}
        minHeight={400}
        ref={ref}
        showCover
        style={{ margin: '0 auto' }}
        usePortrait={false}
        width={600}
      >
        <Page>
          <img src={ProfilePage} />
        </Page>
        <Page number={1} total={pages.length + 3}>
          <span
            style={{
              position: 'absolute',
              left: 15,
              top: 40,
              fontSize: '3rem',
              fontFamily: 'cartoon',
            }}
          >
            {computeTotalYears(experiences)}
          </span>
          <img src={CertPage} />
        </Page>
        {skills.map((skill, index) => {
          return (
            <Page
              key={`skill${index}`}
              number={index + 2}
              total={pages.length + (2 + skills.length)}
            >
              <JobSkills skills={skill} />
            </Page>
          );
        })}
        {pages.map((page, index) => {
          return (
            <Page
              key={`page${index}`}
              number={index + 4}
              total={pages.length + (2 + skills.length)}
            >
              {page}
            </Page>
          );
        })}
        <Page
          number={pages.length + 4}
          total={pages.length + (2 + skills.length)}
        >
          <img src={Contact} />
        </Page>
      </HTMLFlipBook>
    </>
  );
};

export default WebBook;
