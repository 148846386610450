import React from 'react';
import {Bar} from 'react-chartjs-2';
import 'chartjs-adapter-moment';

const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      display: false
    },
    title: {
      display: true,
      text: 'Skills Chart (Years of Experience)',
    },
  },
  indexAxis: 'y',
  scales: {
    y: {
      min: 0,
      max: 11,
      ticks: {
        crossAlign: 'far',
      }
    }
  }
};

const SkillChart = ({skills}: {skills:any[]}) => {
  const skillExperience = skills;
  const labelsArr = [];
  const yearsArr = [];
  const colorsArr = [];

  for(let {name, years, color} of skillExperience){
    labelsArr.push(name);
    yearsArr.push(years);
    colorsArr.push(color);
  }

  const data = {
    labels: labelsArr,
    datasets: [{
      axis: 'y',
      label: 'Years of Experience',
      data: yearsArr,
      fill: false,
      backgroundColor: colorsArr,
      borderColor: colorsArr,
      borderWidth: 1
    }]
  };

  return (
    <Bar data={data} options={options} style={{margin: '10px'}} type={'bar'}/>
  )
};

export default SkillChart;

