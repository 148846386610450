import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Dehaze from '@material-ui/icons/Dehaze';
import { Divider } from '@material-ui/core';

const ITEM_HEIGHT = 48;

export default function MobileMenu({ actions }: { actions: any }) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const turnToPage = (pageNumber: number) => {
    actions.turnToPage(pageNumber);
    handleClose();
  };

  const handleHome = () => {
    turnToPage(0);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="more"
        onClick={handleClick}
      >
        <Dehaze fontSize="large" />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
        anchorEl={anchorEl}
        id="long-menu"
        keepMounted
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={handleHome} selected>
          Home
        </MenuItem>
        <Divider />
        <MenuItem color="inherit" onClick={() => turnToPage(1)}>
          Certifications
        </MenuItem>
        <Divider />
        <MenuItem color="inherit" onClick={() => turnToPage(2)}>
          Skills
        </MenuItem>
        <Divider />
      </Menu>
    </div>
  );
}
