import { Grid } from '@mui/material';
import React from 'react';
import Item from '../../../components/Item/Item';

type Props = {
  header: string;
  logo?: string;
  image?: string;
  fontSize?: string;
};

const BodyMobile = ({ header, image, fontSize }: Props) => {
  return (
    <Grid container gap={1} spacing={2}>
      {image && (
        <Grid item style={{ height: '300px' }} xs={12}>
          <Item style={{ height: '100%' }}>
            <img alt={'image'} height="100%" src={image} width="100%" />
          </Item>
        </Grid>
      )}
      <Grid item xs={12}>
        <Item style={{ height: '100%' }}>
          <div style={{ fontSize }}>{header}</div>
        </Item>
      </Grid>
    </Grid>
  );
};

export default BodyMobile;
