import { Grid } from '@mui/material';
import React from 'react';
// import CompanySummary from '../../components/Home/components/CompanySummary';
// import TechnologyStack from '../../components/Home/components/TechnologyStack';
import { Technology } from '../JobDetail/web-view/components/WebTechnologies';
import Page from '../Page';
import HeaderMobile from '../component/Header/HeaderMobile';
import BodyMobile from '../component/Body/BodyMobile';
import FooterBadgeMobile from '../component/FooterBadge/FooterBadgeMobile';

export interface Summary {
  company: string;
  position: string;
  fromDate: string;
  toDate: string;
  picture: string;
  description: string;
  page: string;
  country: string;
  flagImg: any;
  officeImg: any;
  backgroundColor?: string;
  subBackgroundColor?: string;
  color?: string;
  technologies: Array<Technology>;
  logoHeight?: string;
}

const JobSummaryPageMobile = (summary: Summary) => {
  return (
    <Page>
      <Grid container gap={1} spacing={2}>
        <Grid item xs={12}>
          <HeaderMobile
            color={summary.backgroundColor}
            header={summary.company}
            logoHeight={summary.logoHeight}
            logoPicture={summary.picture}
            subHeader={`(${summary.fromDate} to ${summary.toDate})`}
          />
        </Grid>
        <Grid item xs={12}>
          <BodyMobile
            fontSize={'1rem'}
            header={summary.description}
            image={summary.officeImg}
            logo={summary.flagImg}
          />
        </Grid>
        <Grid item xs={12}>
          <FooterBadgeMobile
            role={summary.position}
            technologies={summary.technologies}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default JobSummaryPageMobile;
