import tpicapLogo from '../assets/images/logo/company/tpicap-logo.png';
import ndf from '../assets/images/tpicap/ndf.jpeg';
import SingaporeFlag from '../assets/images/flags/singapore-flag.png';
import TPICAPSingaporeOffice from '../assets/images/tpicap/tpicap_office.png';
import ReactLogo from '../assets/images/logo/tech/react-logo.png';
import AwsLogo from '../assets/images/logo/tech/aws-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import KubernetesLogo from '../assets/images/logo/tech/kubernetes-logo.png';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import TPArchitecture from '../assets/images/tpicap/tpicap_arch.png';

export default {
  backgroundColor: '#49528e',
  subBackgroundColor: '#78aed0',
  color: '#ffffff',
  company: 'TPICAP Management Services',
  position: 'Senior Fullstack Developer',
  fromDate: 'Jul-01-2022',
  fromDateResume: '07/2022',
  toDate: 'present',
  toDateResume: 'present',
  picture: tpicapLogo,
  description:
    'TP ICAP is a leading interdealer broker that provides innovative solutions to mitigate risks for traders in various financial markets. As an intermediary between buyers and sellers, TP ICAP leverages its extensive network and expertise to offer customized services to its clients. ',
  page: '/resume/job-detail',
  country: 'Singapore',
  flagImg: SingaporeFlag,
  officeImg: TPICAPSingaporeOffice,
  pageNum: 5,
  projects: [
    {
      client: 'Matchbook',
      color: '#092847',
      name: 'NDF 2',
      role: 'Senior Fullstack Developer',
      description: `In my current role, I work on NDF 2 - a sophisticated web application that uses advanced solving algorithms to facilitate interdealer brokers in simulating matched traders for buying and selling financial instruments. 
         The platform displays the results of these simulations in a user-friendly format, allowing users to easily analyze and interpret them. Additionally, users can also copy these results into live matches, enabling them to execute trades with greater efficiency and accuracy.`,
      logo: ndf,
      img: TPArchitecture,
      architecture: TPArchitecture,
      modules: [
        {
          name: 'NDF 2 UI',
          description: 'Core Developer',
          involvements: [
            {
              name: 'Played a key role in the development of new architecture by providing valuable insights and collaborating on all discussions.',
              indent: false,
            },
            {
              name: 'Introduced microfrontends using module federation and presented them to the team as part of our innovative new architecture.',
              indent: false,
            },
            {
              name: 'Built a highly efficient CI/CD pipeline on Cloudbees Jenkins that runs Selenium automation tests, significantly improving the productivity and effectiveness of the QA team.',
              indent: false,
            },
            {
              name: 'Delivered a compelling brownbag session on Cypress end-to-end testing, sharing valuable knowledge and expertise with colleagues.',
              indent: false,
            },
            {
              name: 'Presented React testing library best practices during a brownbag session, showcasing my skills and expertise.',
              indent: false,
            },
            {
              name: 'Designed, developed and built complex UI components for NDF2, delivering exceptional quality work that exceeded expectations.',
              indent: false,
            },
          ],
        },
        {
          name: 'NDF 2 UI (Part 2)',
          description: 'Core Developer',
          involvements: [
            {
              name: 'Applied industry-leading frontend best practices to deliver high-quality code and actively shared my knowledge with colleagues.',
              indent: false,
            },
            {
              name: 'Led UX design work via Figma and engaged primary stakeholders in productive discussions to ensure project success.',
              indent: false,
            },
            {
              name: 'Provided creative UX/UI proposals to primary stakeholders for the new NDF2 design, delivering exceptional value and impact to the project.',
              indent: false,
            },
            {
              name: 'Played a pivotal role in saving a failing project by providing PO with valuable proposals and code reworks that led to successful delivery of a high-quality UI in production.',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: ReactLogo,
      title: 'ReactJS',
      info: 'Built UI in NDF 2 using ReactJS',
    },
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in NDF 2 using Spring Boot',
    },
    {
      logo: KubernetesLogo,
      title: 'Kubernetes',
      info: 'Container orchestration using Kubernetes',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: AwsLogo,
      title: 'AWS',
      info: 'Powered by AWS',
    },
  ],
};
