import React from 'react';
import {isMobileOnly} from 'react-device-detect';
import {Toolbar} from '@material-ui/core';
import logo from '../../assets/images/logo/personal/logo.svg';
import ResponsiveMenu from '../menu/ResponsiveMenu';

const ResponsiveToolbar = ({actions}:{actions:any}) => {

  const home = () =>{
    actions.turnToPage(0);
  }
  return (<Toolbar>
    {!isMobileOnly && <>
      <img alt="Company Logo" height={'90px'} onClick={home} src={logo} width={'200px'}/>
      <span style={{flexGrow: 1}} />
      <ResponsiveMenu actions={actions}/></>
    }
    {isMobileOnly && <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
      <img alt="Company Logo" height={'70px'} onClick={home} src={logo} width={'100px'}/>
      <ResponsiveMenu actions={actions}/>
    </div>
    }
  </Toolbar>)
}

export default ResponsiveToolbar;
