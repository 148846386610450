import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

const options = {
  maintainAspectRatio: false,
  aspectRatio: 1,
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Skills Chart (Years of Experience)',
      fontSize: 20,
    },
  },
  indexAxis: 'y',
  scales: {
    y: {
      min: 0,
      max: 30,
      ticks: {
        crossAlign: 'far',
        font: {
          size: 12, // Lower the font size of the ticks
        },
      },
      scaleLabel: {
        display: true,
        labelString: 'Years of Experience', // Add a label for the scale
        font: {
          size: 14, // Add a font size for the label
        },
      },
    },
    x: {
      // Add configuration for the x axis
      ticks: {
        font: {
          size: 12, // Lower the font size of the ticks
        },
      },
    },
  },
};

const SkillChartMobile = ({ skills }: { skills: any[] }) => {
  const skillExperience = skills;
  const labelsArr = [];
  const yearsArr = [];
  const colorsArr = [];

  for (let { name, years, color } of skillExperience) {
    labelsArr.push(name);
    yearsArr.push(years);
    colorsArr.push(color);
  }

  const data = {
    labels: labelsArr,
    datasets: [
      {
        axis: 'y',
        label: 'Years of Experience',
        data: yearsArr,
        fill: false,
        backgroundColor: colorsArr,
        borderColor: colorsArr,
        borderWidth: 1,
      },
    ],
  };

  return <Bar data={data} options={options} type={'bar'} />;
};

export default SkillChartMobile;
