import CompanyLogo from '../assets/images/logo/company/macquarie-logo.jpeg';
import Flag from '../assets/images/flags/philippine-flag.jpg';
import Office from '../assets/images/macquarie/macquarie-office.jpeg';
import ComplianceTool from '../assets/images/projects/wealth-macquarie/Wealth Compliance Report - Macquarie.jpg';
import JasperMigration from '../assets/images/projects/wealth-macquarie/Jasper-Migration-Macquarie.jpg';
import JasperLogo from '../assets/images/logo/tech/jasper-logo.png';
import OracleLogo from '../assets/images/logo/tech/oracle-db-logo.jpg';
import JavaLogo from '../assets/images/logo/tech/java-logo.jpeg';
import SpringBootLogo from '../assets/images/logo/tech/spring-boot-logo.png';
import SpringBatchLogo from '../assets/images/logo/tech/spring-batch.png';
import Compliance from '../assets/images/projects/wealth-macquarie/compliance.jpg';
import Migration from '../assets/images/projects/wealth-macquarie/cloud-migration.jpg';

export default {
  backgroundColor: '#26282f',
  subBackgroundColor: '#28292b',
  color: '#ffffff',
  company: 'Macquarie Offshore Services Inc.',
  position: 'Java Manager',
  fromDate: 'Aug-01-2016',
  fromDateResume: '08/2016',
  toDate: 'Sep-29-2018',
  toDateResume: '09/2018',
  picture: CompanyLogo,
  description:
    'Macquarie offshore services in Makati City Philippines is one of the ' +
    'regional headquarters to provide IT Services internally within the company. ' +
    'I worked here as a Java Manager for 2 years 2 months.',
  page: '/resume/job-detail',
  country: 'Manila, Philippines',
  flagImg: Flag,
  officeImg: Office,
  pageNum: 17,
  projects: [
    {
      client: 'Compliance Regulatory Reporting',
      name: 'ATO Report Generation Tool',
      role: 'Java Manager',
      color: '#092847',
      description:
        'Every financial year as part of Regulatory Compliance, Macquarie should send and declare the trades performed by the clients within the financial year.' +
        ' This report should be in a flat file format and the mapping is provided in the ATO (Australian Taxation Office) website. ' +
        ' This application will automate the generation of this report every year and generate a flat file and will be used by Macquarie to upload into the ATO (Australian Taxation Office) website. ',
      logo: Compliance,
      img: ComplianceTool,
      architecture: ComplianceTool,
      modules: [
        {
          name: 'Automated Report Generation Tool',
          description: 'Lead developer for ATO tool',
          involvements: [
            {
              name: 'Played a crucial role in a collaborative effort alongside a Business Analyst to map trade attributes into ATO flat file format, facilitating smooth and efficient reporting processes',
              indent: false,
            },
            {
              name: 'Collaborated closely with a DBA to design the database for an innovative ATO report tool, ensuring optimal performance and reliability at scale',
              indent: false,
            },
            {
              name: 'Demonstrated outstanding leadership skills by expertly mentoring a team of two developers and one QA, fostering growth and development while achieving critical project milestones',
              indent: false,
            },
            {
              name: 'Developed a cutting-edge report generation tool with Spring Boot & Spring Batch, showcasing top-notch skills in full-stack Java development and design',
              indent: false,
            },
            {
              name: 'Expertly coordinated with multiple teams to obtain necessary trade and fx extract files for successful end-to-end integration of the new ATO report tool',
              indent: false,
            },
          ],
        },
      ],
    },
    {
      client: 'Jasper Service Migration',
      name: 'Migrate Jasper Server',
      role: 'Java Manager',
      color: '#092847',
      description:
        'This project is to migrate Jasper Report Server from version 3 to version 5 and deployed it into the Macquarie private AWS Cloud.' +
        'As part of migration work, the old oracle database should also be migrated to MySql RDS for ease of support in Jasper Server version 5.' +
        'The new Jasper Server is deployed in 2 availability zones and with an ALB. ',
      logo: Migration,
      img: JasperMigration,
      architecture: JasperMigration,
      modules: [
        {
          name: 'Decommission and migrate to cloud',
          description: 'Lead developer for tech refresh',
          involvements: [
            {
              name: 'Demonstrated exceptional analytical skills by assessing the existing Jasper Server version 3 and developing a successful proof of concept in upgrading it to the latest version',
              indent: false,
            },
            {
              name: 'Collaborated closely with a highly skilled Technical Architect to implement cutting-edge security and licensing protocols for the new Jasper Server 5, ensuring optimal performance and reliability at scale',
              indent: false,
            },
            {
              name: 'Expertly setup the cloud environment for seamless integration of the new Jasper Server, showcasing top-notch skills in modern systems management and administration',
              indent: false,
            },
            {
              name: 'Successfully coordinated with multiple teams on the testing plan for the new Jasper Server, ensuring comprehensive and thorough testing processes that resulted in seamless implementation and deployment',
              indent: false,
            },
          ],
        },
        {
          name: 'Decommission and migrate to cloud (Part 2)',
          description: 'Lead developer for tech refresh',
          involvements: [
            {
              name: 'Proactively setup a new MySQL database environment for the new Jasper Server, demonstrating a strong ability to anticipate project needs and quickly develop effective solutions to complex problems',
              indent: false,
            },
            {
              name: 'Migrated all existing reports from the outdated Jasper Server to the latest version, ensuring that critical data was preserved while enabling new and powerful reporting capabilities',
              indent: false,
            },
          ],
        },
      ],
    },
  ],
  technologies: [
    {
      logo: SpringBootLogo,
      title: 'Spring Boot',
      info: 'Built backend services in CLIPP using Spring Boot',
    },
    {
      logo: JavaLogo,
      title: 'Java 11',
      info: 'Java as core backend language',
    },
    {
      logo: OracleLogo,
      title: 'Oracle Database',
      info: 'CLIPP database is in Oracle',
    },
    {
      logo: JasperLogo,
      title: 'Jasper Reports',
      info: 'Used for generating trade reports',
    },
    {
      logo: SpringBatchLogo,
      title: 'Spring Batch',
      info: 'Used for batch processing to generate ATO flat file reports',
    },
  ],
};
