import React from 'react';
import MobileMenu from './MobileMenu';
import {isMobileOnly} from 'react-device-detect';
import WebMenu from './WebMenu';

const ResponsiveMenu = ({actions}:{actions: any}) => {
  return (<>{!isMobileOnly && <WebMenu actions={actions}/>}
    {isMobileOnly && <MobileMenu actions={actions}/>}</>)
}

export default ResponsiveMenu;
