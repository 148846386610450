import { Paper, Theme } from '@mui/material';
import { styled } from '@mui/system';

const Item = styled(Paper)(({ theme }: { theme?: Theme }) => ({
  backgroundColor: theme?.palette?.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme?.typography?.body2,
  padding: theme?.spacing(1),
  textAlign: 'center',
  color: theme?.palette?.text?.secondary,
}));

export default Item;
